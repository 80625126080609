import React from 'react'

export default () => {
  return (
    <div>
      go to specific pages
      <br />
      dashboard or other base pages
    </div>
  )
}
